import axios from "axios";
import cookie from "react-cookies";
import * as settings from '../appsettings';
import requestHandler from "../Interceptors/RequestHandler";
import responseHandler from "../Interceptors/ResponseHandler";
import exceptionHandler from "../Interceptors/ExceptionHandler";

const httpClient = axios.create({
    baseURL: process.env["REACT_APP_API_BASE_URL"]
});

httpClient.interceptors.request.use(requestHandler);

httpClient.interceptors.response.use(responseHandler, exceptionHandler);

export default httpClient;
