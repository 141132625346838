import { Accordion, Alert, Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import * as settings from "../../../appsettings";
import httpClient from "../../../Services/ApiService";
import endpoints from "../../../apiendpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { HttpStatusCode } from "axios";


export default function ModularInverse() {

    const [isProcessing, setProcessing] = useState(false);
    const [res, setRes] = useState(null);
    const resultRef = useRef();
    const [table, setTable] = useState([]);

    const schema = yup
        .object({
            n: yup
                .number()
                .typeError("Vui lòng nhập n")
                .required("Vui lòng nhập n")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            modulo: yup
                .number()
                .typeError("Vui lòng nhập modulo")
                .required("Vui lòng nhập modulo")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setError
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    async function onSubmit(data) {
        setProcessing(true);
        try {
            const param = Object.keys(data)
                .map((k) => `${k}=${data[k]}&`)
                .join("");

            let url = endpoints["other.modular_inverse"].concat("?", param);
            const res = await httpClient.get(url);

            setRes(res.data);
            const tmpTable = res?.data?.result?.table;
            tmpTable[0][2] = null;
            setTable(tmpTable);
            resultRef.current?.focus();
        } catch (error) {
            if (error.status == HttpStatusCode.BadRequest && error.detail.error == "no_modular_inverse") {
                setError("n", { type: 'custom', message: `Không tồn tại nghịch đảo modulo ${watch("modulo")} của ${watch("n")}` })
            }
        } finally {
            setProcessing(false);
        }
    }

    function onChange(e, fieldOnChange) {
        if (res)
            setRes(null);
        fieldOnChange(e.target.value);
    }

    return <>
        <Container className="py-2">
            <h1>Nghịch đảo modulo</h1>
            <Card className="p-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Label className="ps-2 mt-1">Nhập n:</Form.Label>
                            <Controller name="n" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.n)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.n?.message}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Label className="ps-2 mt-1">Nhập modulo:</Form.Label>
                            <Controller name="modulo" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.modulo)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.modulo?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <div className="text-end">
                        <Button
                            disabled={isProcessing}
                            type="submit"
                            variant="success"
                            className="mt-2"
                        >
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                />
                            )}
                            <span>Tính kết quả</span>
                        </Button>
                    </div>

                    <Form.Label className="ps-2 mt-1">Kết quả:</Form.Label>
                    <Form.Control size="lg" ref={resultRef} value={res?.result?.result ?? ""}></Form.Control>
                </form>

                {res?.succeeded &&
                    <div>
                        <Table className="table table-bordered mt-3 w-auto">
                            <thead>
                                <tr>
                                    <th className="text-nowrap">X</th>
                                    <th className="text-nowrap">B</th>
                                    <th className="text-nowrap">Y</th>
                                </tr>
                            </thead>
                            <tbody>
                                {table.map((r, i) => <tr>
                                    {r.map((c, i) => <td className="text-nowrap">{c}</td>)}
                                </tr>)}
                            </tbody>
                        </Table>
                        <MathJaxContext>
                            <MathJax>{`
                            \\(
                            kq = ${res?.result?.b} \\ mod \\ ${watch('modulo')} = ${res?.result?.result}
                            \\)`}
                            </MathJax>
                        </MathJaxContext>
                    </div>}
            </Card>
        </Container >
    </>
}