import { Accordion, Col, Container, Row } from "react-bootstrap";
import Encode from "./Encode";
import Decode from "./Decode";

export default function Caesar() {
  return (
    <>
      <Container className="py-2">
        <h1>Caesar</h1>
        <Accordion className="mb-3" alwaysOpen>
          <Encode></Encode>
          <Decode></Decode>
        </Accordion>
      </Container >
    </>
  );
}
