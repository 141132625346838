import { Accordion, Col, Container, Row } from "react-bootstrap";
import Decode from "./Decode";
import Encode from "./Encode";

export default function Affine() {
    return <>
        <Container className="py-2">
            <h1>Affine</h1>
            <Accordion className="mb-3" alwaysOpen>
                <Encode></Encode>
                <Decode></Decode>
            </Accordion>
        </Container >
    </>
}