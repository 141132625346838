import { Image, Nav, NavLink } from "react-bootstrap";

export default function Logo({ size="sm", className="" }) {
    const sizes = {
        "sm": 110,
        "md": 140
    };
    return <>
        <NavLink href="/" className={className}>
            <Image src="/images/logo_small.png" width={sizes[size]} />
        </NavLink>
    </>;
}