import {
    Accordion,
    AlertHeading,
    Button,
    Card,
    Form,
    FormControl,
    Spinner,
    Table,
} from "react-bootstrap";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import * as settings from "../../../appsettings";
import httpClient from "../../../Services/ApiService";
import endpoints from "../../../apiendpoints";

export default function Decode() {

    const [isProcessing, setProcessing] = useState(false);
    const [res, setRes] = useState(null);
    const alphabet = settings.ALPHABET;
    const resultRef = useRef();

    const schema = yup
        .object({
            key: yup
                .string()
                .typeError("Vui lòng nhập khóa")
                .required("Vui lòng nhập khóa")
                .max(settings.MAX_PLAIN_TEXT_LENGTH, `Tối đa ${settings.MAX_PLAIN_TEXT_LENGTH} ký tự`)
                .test({
                    name: "alphabet_valid",
                    test: function (value) {
                        return ![...value.toLowerCase()].some(
                            (char) => !alphabet.includes(char)
                        );
                    },
                    message:
                        "Chỉ chấp nhận các ký tự từ a-z (không phân biệt hoa thường)",
                }),
            cipherText: yup
                .string()
                .required("Vui lòng nhập bản mã")
                .max(settings.MAX_PLAIN_TEXT_LENGTH, `Tối đa ${settings.MAX_PLAIN_TEXT_LENGTH} ký tự`)
                .test({
                    name: "alphabet_valid",
                    test: function (value) {
                        return ![...value.toLowerCase()].some(
                            (char) => !alphabet.includes(char)
                        );
                    },
                    message:
                        "Chỉ chấp nhận các ký tự từ a-z (không phân biệt hoa thường)",
                }),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    async function onSubmit(data) {
        setProcessing(true);
        try {
            const param = Object.keys(data)
                .map((k) => `${k}=${data[k]}&`)
                .join("");
            let url = endpoints["cipher.playfair.decode"].concat("?", param);
            const res = await httpClient.get(url);
            setRes(res.data);
            resultRef.current?.focus();
        } catch (error) {
        } finally {
            setProcessing(false);
        }
    }

    function onChange(e, fieldOnChange) {
        if (res)
            setRes(null);
        fieldOnChange(e.target.value);
    }

    return <>
        <Accordion.Item eventKey="1" defaultChecked>
            <Accordion.Header>
                <h5>Giải mã</h5>
            </Accordion.Header>
            <Accordion.Body className="p-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Label className="ps-2">Khóa k:</Form.Label>
                    <Form.Group>
                        <Controller control={control} name="key" render={({ field }) => (
                            <Form.Control
                                {...field}
                                isInvalid={Boolean(errors?.key)}
                                size="lg"
                                type="text"
                                onChange={e => onChange(e, field.onChange)}
                            ></Form.Control>
                        )}></Controller>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.key?.message}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Label className="ps-2 mt-1">Bản mã:</Form.Label>
                    <Form.Group>
                        <Controller name="cipherText"
                            control={control}
                            render={({ field }) => (
                                <Form.Control
                                    {...field}
                                    isInvalid={Boolean(errors?.cipherText)}
                                    size="lg"
                                    type="text"
                                    onChange={(e) => onChange(e, field.onChange)}
                                ></Form.Control>
                            )}></Controller>
                        <small><i>*J được chuyển thành I</i></small>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.cipherText?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div className="text-end">
                        <Button
                            disabled={isProcessing}
                            type="submit"
                            variant="success"
                            className="mt-2"
                        >
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                />
                            )}
                            <span>Giải mã</span>
                        </Button>
                    </div>

                    <Form.Label className="ps-2 mt-1">Bản rõ:</Form.Label>
                    <Form.Control size="lg" type="text"
                        ref={resultRef}
                        value={res?.result?.plainText?.toUpperCase() ?? ""} />
                </form>

                {res?.succeeded && <Table className="table table-bordered mt-3">
                    <body>
                        {res?.result?.tableString?.match(/.{1,5}/g)
                            .map(row => <tr>
                                {row.split('').map(p => <td>{p === 'i' ? 'I/J' : p.toUpperCase()}</td>)}
                            </tr>)}
                    </body>
                </Table>}

                {res?.succeeded && <div className="table-responsive mt-2">
                    <Table className="table table-bordered">
                        <body>
                            <tr>
                                <th className="text-nowrap">Bản mã</th>
                                {res?.result?.newCipherText?.match(/.{1,2}/g)
                                    .map(p => <td className="text-nowrap">{p.toUpperCase()}-({alphabet.indexOf(p[0])}, {alphabet.indexOf(p[1])})</td>)}
                            </tr>
                            <tr>
                                <th className="text-nowrap">Bản rõ</th>
                                {res?.result?.plainText?.match(/.{1,2}/g)
                                    .map(p => <td className="text-nowrap">{p.toUpperCase()}-({alphabet.indexOf(p[0])}, {alphabet.indexOf(p[1])})</td>)}
                            </tr>
                        </body>
                    </Table>
                </div>}
            </Accordion.Body>
        </Accordion.Item>
    </>
}