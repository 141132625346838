import {
    Accordion,
    AlertHeading,
    Button,
    Card,
    Col,
    Form,
    FormControl,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import * as settings from "../../../appsettings";
import httpClient from "../../../Services/ApiService";
import endpoints from "../../../apiendpoints";
import { HttpStatusCode } from "axios";

export default function Encode() {

    const [isProcessing, setProcessing] = useState(false);
    const [res, setRes] = useState(null);
    const alphabet = settings.ALPHABET;
    const resultRef = useRef();

    const schema = yup
        .object({
            a: yup
                .number()
                .typeError("Vui lòng nhập a")
                .required("Vui lòng nhập a")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            b: yup
                .number()
                .typeError("Vui lòng nhập a")
                .required("Vui lòng nhập a")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            plainText: yup
                .string()
                .required("Vui lòng nhập bản rõ")
                .max(settings.MAX_PLAIN_TEXT_LENGTH, `Tối đa ${settings.MAX_PLAIN_TEXT_LENGTH} ký tự`)
                .test({
                    name: "alphabet_valid",
                    test: function (value) {
                        return ![...value.toLowerCase()].some(
                            (char) => !alphabet.includes(char)
                        );
                    },
                    message:
                        "Chỉ chấp nhận các ký tự từ a-z (không phân biệt hoa thường)",
                }),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        setError,
        control,
        watch
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    async function onSubmit(data) {
        setProcessing(true);
        try {
            const param = Object.keys(data)
                .map((k) => `${k}=${data[k]}&`)
                .join("");
            let url = endpoints["cipher.affine_encode"].concat("?", param);
            const res = await httpClient.get(url);
            setRes(res.data);
            resultRef.current?.focus();
        } catch (error) {
            if (error.status == HttpStatusCode.BadRequest
                && error.detail?.error == "no_modular_inverse") {
                setError('a', {
                    type: 'custom',
                    message: `Không tồn tại nghịch đảo modulo ${alphabet.length} của ${data?.a}`
                });
            }
        } finally {
            setProcessing(false);
        }
    }

    function onChange(e, fieldOnChange) {
        if (res)
            setRes(null);
        fieldOnChange(e.target.value);
    }

    return <>
        <Accordion.Item eventKey="0" defaultChecked>
            <Accordion.Header>
                <h5>Mã hóa</h5>
            </Accordion.Header>
            <Accordion.Body className="p-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Label className="ps-2">{'k = (a, b)'}</Form.Label>
                    <Form.Group>
                        <Row>
                            <Col xs={6} sm={6} md={6}>
                                <Controller name="a" control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            {...field}
                                            isInvalid={Boolean(errors?.a)}
                                            size="lg"
                                            type="number"
                                            placeholder="a"
                                            min={0}
                                            onChange={e => onChange(e, field.onChange)}
                                        ></Form.Control>
                                    )}></Controller>
                                <Form.Control.Feedback type="invalid" className="ps-2">
                                    {errors.a?.message}
                                </Form.Control.Feedback>
                            </Col>
                            <Col xs={6} sm={6} md={6}>
                                <Controller name="b" control={control}
                                    render={({ field }) => (
                                        <Form.Control
                                            {...field}
                                            isInvalid={Boolean(errors?.b)}
                                            size="lg"
                                            type="number"
                                            placeholder="b"
                                            min={0}
                                            onChange={e => onChange(e, field.onChange)}
                                        ></Form.Control>
                                    )}></Controller>
                                <Form.Control.Feedback type="invalid" className="ps-2">
                                    {errors.b?.message}
                                </Form.Control.Feedback>
                            </Col>
                        </Row>
                    </Form.Group>
                    <Form.Label className="ps-2 mt-1">Bản rõ:</Form.Label>
                    <Form.Group>
                        <Controller name="plainText" control={control}
                            render={({ field }) => (
                                <Form.Control
                                    {...field}
                                    isInvalid={Boolean(errors?.plainText)}
                                    size="lg"
                                    type="text"
                                    onChange={e => onChange(e, field.onChange)}
                                ></Form.Control>
                            )}></Controller>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.plainText?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <div className="text-end">
                        <Button
                            disabled={isProcessing}
                            type="submit"
                            variant="success"
                            className="mt-2"
                        >
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                />
                            )}
                            <span>Mã hóa</span>
                        </Button>
                    </div>

                    <Form.Label className="ps-2 mt-1">Bản mã:</Form.Label>
                    <Form.Control size="lg" type="text"
                        ref={resultRef}
                        value={res?.result?.cipherText?.toUpperCase() ?? ""} />
                </form>

                {res?.succeeded && <div className="table-responsive mt-3">
                    <Table className="table table-bordered ">
                        <body>
                            <tr>
                                <th className="text-nowrap">Bản rõ</th>
                                {watch('plainText').split('').map(p => <td>{p.toUpperCase()}-{alphabet.indexOf(p.toLowerCase())}</td>)}
                            </tr>
                            <tr>
                                <th className="text-nowrap">Bản mã</th>
                                {res?.result?.cipherText?.split('').map(p => <td>{p.toUpperCase()}-{alphabet.indexOf(p.toLowerCase())}</td>)}
                            </tr>
                        </body>
                    </Table>
                </div>}
            </Accordion.Body>
        </Accordion.Item>
    </>
}