import ReactGA from "react-ga4";

export function init() {
    ReactGA.initialize(process.env["REACT_APP_GA_MEASUREMENT_ID"]);

    ReactGA.set({
        environment: process.env.NODE_ENV === "production" ? "production" : "development",
    });
}

export function logPageView(pagePath) {
    ReactGA.send({
        hitType: "pageview",
        page: pagePath
    });
}

export function trackAuthUser() {
    ReactGA.event({
        category: 'Auth',
        action: 'Human verify',
        label: `Verify at ${new Date().toLocaleString()}`
    })
}