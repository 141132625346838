export default function Footer({ className = "" }) {
    return <>
        <div className={`text-center ${className}`}>
            <p className="me-2 text-muted mb-0">
                <small>&copy; {new Date().getFullYear()} - Crafted with 💻 by NguyenAnhDo</small>
            </p>

            <div className="d-flex flex-wrap justify-content-center">
                <small><a href="/contact" className="text-black me-2">Thông tin liên hệ</a></small>
                <small><a href="/terms-of-use" className="text-black me-2">Điều khoản sử dụng</a></small>
            </div>
            <p className="text-muted"><small>Phiên bản: {process.env["REACT_APP_VERSION"]}</small></p>
        </div>
    </>
}