export const API_BASE_URL = 'https://ncipherapi.somee.com/api/';
// export const API_BASE_URL = 'https://localhost:7162/api/';
export const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';
export const MAX_PLAIN_TEXT_LENGTH = 500;
export const MAX_CIPHER_TEXT_LENGTH = 500;
export const MAX_INT_KEY = 1000;
export const MAX_STRING_KEY_LENGTH = 500;
export const OAUTH2_GOOGLE_CLIENT_ID = "";
export const OAUTH2_FACEBOOK_APP_ID = "";
export const OAUTH2_GITHUB_CLIENT_ID = "";
export const HUMAN_VERIFY_URL = "/hooman-verify";
export const ERR_429_URL = "/error/429";