import { Accordion, Col, Container, Row } from "react-bootstrap";
import Encode from "./Encode";
import Decode from "./Decode";
import FindKeys from "./FindKeys";

export default function RSA() {
    return (
        <>
            <Container className="py-2">
                <h1>RSA</h1>
                <Accordion className="mb-3" alwaysOpen>
                    <FindKeys></FindKeys>
                    <Encode></Encode>
                    <Decode></Decode>
                </Accordion>
            </Container >
        </>
    );
}
