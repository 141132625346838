import { Badge, Card, Container } from "react-bootstrap";

export default function Contact() {
    return <Container className="text-center mt-4">
        <h2>Thông tin liên hệ</h2>
        <p>Mọi yêu cầu hỗ trợ, báo cáo sự cố xin vui lòng liên hệ qua</p>
        <Card className="text-center" style={{ maxWidth: '400px', margin: '0 auto' }}>
            <img src="/images/email_contact.png" style={{width: "90px", margin: "0 auto"}}/>
            <h4 style={{ color: "#002bb5" }}>Địa chỉ email</h4>
            <h5>{process.env["REACT_APP_SUPPORT_EMAIL"]}</h5>
        </Card>
    </Container>
}