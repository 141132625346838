import { Container } from "react-bootstrap";

export default function TermsOfUse() {
    return <>
        <Container>
            <h2 className="text-center mt-4">Điều khoản sử dụng</h2>
            <h5 className="fw-bold">1. Giới thiệu</h5>
            <p style={{ textAlign: 'justify' }}>Website ncipher.org (sau đây gọi là "Website") cung cấp các công cụ giải mã, mã hóa, tính toán hoàn toàn
                miễn phí. Khi truy cập và sử dụng Website đồng nghĩa với việc bạn đồng ý với các điều khoản dưới đây. Nếu bạn không
                đồng ý với bất kỳ điều khoản nào dưới đây, vui lòng ngưng sử dụng Website.
            </p>
            <h5 className="fw-bold">2. Tính chất của thông tin cung cấp</h5>
            <p style={{ textAlign: 'justify' }}>Mọi thông tin và kết quả tính toán được cung cấp trên Website chỉ mang tính chất tham khảo. Chúng tôi không cam kết hoặc đảm bảo tính chính xác,
                đầy đủ, hoặc phù hợp với mọi mục đích sử dụng.
            </p>
            <p style={{ textAlign: 'justify' }}>Người dùng tự chịu trách nhiệm hoàn toàn trong việc kiểm tra và xác minh tính chính xác của thông tin hoặc kết quả tính toán trước khi sử dụng trong bất kỳ tình huống nào, bao gồm thi cử, học tập, hoặc công việc.</p>
            <h5 className="fw-bold">3. Tuyên bố miễn trừ trách nhiệm</h5>
            <p style={{ textAlign: 'justify' }}>Chúng tôi không chịu trách nhiệm đối với bất kỳ thiệt hại, mất mát, hoặc tổn thất nào phát sinh từ việc sử dụng thông tin hoặc kết quả tính toán do Website cung cấp.</p>
            <p className="text-center">---</p>
        </Container>
    </>
}