import cookies from "react-cookies";
import { Navigate } from "react-router-dom";
import * as settings from "../../appsettings";
import Decode from "../../Services/JwtService";

export default function AuthRequired({children}) {

    const token = cookies.load("app_access_token");
    const currentTime = Math.floor(Date.now() / 1000)
    const decoded = Decode(token);
    if (!token || !decoded || (decoded.exp - currentTime) <= 120) {
        return <Navigate to={settings.HUMAN_VERIFY_URL} />;
    }
    return children;
}