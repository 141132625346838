import { Accordion, Col, Container, Row } from "react-bootstrap";
import Encode from "./Encode";
import Decode from "./Decode";
import { MathJax, MathJaxContext } from "better-react-mathjax";

export default function Hill() {
    return (
        <>
            <Container className="py-2">
                <h1>Hill</h1>
                <Accordion className="mb-3" alwaysOpen>
                    <Encode></Encode>
                    <Decode></Decode>
                </Accordion>
            </Container >
        </>
    );
}
