import {
    Accordion,
    AlertHeading,
    Button,
    Card,
    Col,
    Form,
    FormControl,
    Row,
    Spinner,
    Table,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import * as settings from "../../../appsettings";
import httpClient from "../../../Services/ApiService";
import endpoints from "../../../apiendpoints";
import { HttpStatusCode } from "axios";
import { MathJax, MathJaxContext } from "better-react-mathjax";

export default function Decode() {

    const [isProcessing, setProcessing] = useState(false);
    const [res, setRes] = useState(null);
    const alphabet = settings.ALPHABET;
    const resultRef = useRef();

    const config = {
        tex: {
            macros: {
                mathsf: ["{\\mathbf{#1}}", 1], // Định nghĩa macro ép phông serif
            },
        },
    };

    const schema = yup
        .object({
            c: yup
                .number()
                .typeError("Vui lòng nhập c")
                .required("Vui lòng nhập c")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            p: yup
                .number()
                .typeError("Vui lòng nhập p")
                .required("Vui lòng nhập p")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            q: yup
                .number()
                .typeError("Vui lòng nhập q")
                .required("Vui lòng nhập q")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            e: yup
                .number()
                .typeError("Vui lòng nhập e")
                .required("Vui lòng nhập e")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setError,
        watch,
        control
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    async function onSubmit(data) {
        setRes({});
        setProcessing(true);
        try {
            const param = Object.keys(data)
                .map((k) => `${k}=${data[k]}&`)
                .join("");
            let url = endpoints["cipher.rsa.decode"].concat("?", param);
            const res = await httpClient.get(url);
            setRes(res.data);
            resultRef.current?.focus();
        } catch (error) {
            if (error.status == HttpStatusCode.BadRequest
                && error.detail?.error == "no_modular_inverse") {
                setError('e', {
                    type: 'custom',
                    message: `Không tồn tại nghịch đảo modulo φ của ${watch('e')}`
                });
            }
        } finally {
            setProcessing(false);
        }
    }

    function onChange(e, fieldOnChange) {
        if (res)
            setRes(null);
        fieldOnChange(e.target.value);
    }

    return <Accordion.Item eventKey="2" defaultChecked>
        <Accordion.Header>
            <h5>Giải mã</h5>
        </Accordion.Header>
        <Accordion.Body className="p-2">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Col xs={12} sm={12} md={6}>
                        <Form.Label className="ps-2 mt-1">Nhập bản mã (c):</Form.Label>
                        <Controller name="c" control={control}
                            render={({ field }) => (
                                <Form.Control
                                    {...field}
                                    isInvalid={Boolean(errors?.c)}
                                    size="lg"
                                    type="number"
                                    min={0}
                                    onChange={e => onChange(e, field.onChange)}
                                ></Form.Control>
                            )}></Controller>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.c?.message}
                        </Form.Control.Feedback>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Form.Label className="ps-2 mt-1">Nhập p:</Form.Label>
                        <Controller name="p" control={control}
                            render={({ field }) => (
                                <Form.Control
                                    {...field}
                                    isInvalid={Boolean(errors?.p)}
                                    size="lg"
                                    type="number"
                                    min={0}
                                    onChange={e => onChange(e, field.onChange)}
                                ></Form.Control>
                            )}></Controller>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.p?.message}
                        </Form.Control.Feedback>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Form.Label className="ps-2 mt-1">Nhập q:</Form.Label>
                        <Controller name="q" control={control}
                            render={({ field }) => (
                                <Form.Control
                                    {...field}
                                    isInvalid={Boolean(errors?.q)}
                                    size="lg"
                                    type="number"
                                    min={0}
                                    onChange={e => onChange(e, field.onChange)}
                                ></Form.Control>
                            )}></Controller>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.q?.message}
                        </Form.Control.Feedback>
                    </Col>

                    <Col xs={12} sm={12} md={6}>
                        <Form.Label className="ps-2 mt-1">Nhập e:</Form.Label>
                        <Controller name="e" control={control}
                            render={({ field }) => (
                                <Form.Control
                                    {...field}
                                    isInvalid={Boolean(errors?.e)}
                                    size="lg"
                                    type="number"
                                    min={0}
                                    onChange={e => onChange(e, field.onChange)}
                                ></Form.Control>
                            )}></Controller>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.e?.message}
                        </Form.Control.Feedback>
                    </Col>
                </Row>

                <div className="text-end">
                    <Button
                        disabled={isProcessing}
                        type="submit"
                        variant="success"
                        className="mt-2"
                    >
                        {isProcessing && (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                                className="me-1"
                            />
                        )}
                        <span>Giải mã</span>
                    </Button>
                </div>
            </form>


            <Form.Label className="ps-2 mt-1">Bản rõ:</Form.Label>
            <Form.Control size="lg" type="text"
                ref={resultRef}
                value={res?.result?.plainNumber ?? ""} />

            <div style={{ overflowX: 'auto' }}>
                {res?.succeeded && <MathJaxContext config={config}>
                    <MathJax className="mt-3 ps-2">
                        {`
                    \\(
                    n = p * q = ${watch('p')} * ${watch('q')} = ${res?.result?.n}
                    \\)`}
                    </MathJax>
                    <MathJax className="mt-2 ps-2">
                        {`
                    \\(
                    \\varphi(n) = (p - 1) * (q - 1) = (${watch('p')} - 1) * (${watch('q')} - 1) = ${res?.result?.phin}
                    \\)`}
                    </MathJax>
                    <MathJax className="mt-2 ps-2">
                        {`
                    \\(
                    \\\\d = e^{-1} \\ mod \\ \\varphi(n) = ${watch('e')}^{-1} \\ mod \\ ${res?.result?.phin} = ${res?.result?.d}
                    \\)`}
                    </MathJax>
                    <MathJax className="mt-2 ps-2">
                        {`
                    \\(
                    \\\\p = c^d \\ mod \\ n = ${watch('c')}^{${res?.result?.d}} \\ mod \\ ${res?.result?.n} = ${res?.result?.plainNumber}
                    \\)`}
                    </MathJax>
                </MathJaxContext>}
            </div>
        </Accordion.Body>
    </Accordion.Item>
}