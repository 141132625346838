import { BrowserRouter, Route, Router, Routes, useNavigate } from "react-router-dom";
import { Header } from "./Components/Layouts/Header";
import Caesar from "./Components/Ciphers/Caesar/Caesar";
import SocialLogin from "./Components/User/SocialLogin";
import HeaderBase from "./Components/Layouts/HeaderBase";
import Affine from "./Components/Ciphers/Affine/Affine";
import DiffieHellman from "./Components/Ciphers/DiffieHellman/DiffieHellman";
import PlayFair from "./Components/Ciphers/PlayFair/PlayFair";
import Hill from "./Components/Ciphers/Hill/Hill";
import RSA from "./Components/Ciphers/RSA/RSA";
import Vigenere from "./Components/Ciphers/Vigenere/Vigenere";
import BotDefeat from "./Components/Auth/BotDefeat";
import ModularInverse from "./Components/Ciphers/Others/ModularInverse";
import TooManyRequests from "./Components/Commons/TooManyRequests";
import Footer from "./Components/Commons/Footer";
import Contact from "./Components/Commons/Contact";
import Modulo from "./Components/Ciphers/Others/Modulo";
import { useEffect } from "react";
import * as settings from "./appsettings";
import cookies from "react-cookies";
import AuthRequired from "./Components/Commons/AuthRequired";
import Home from "./Components/Ciphers/Home";
import TermsOfUse from "./Components/Commons/TermsOfUse";
import * as ga4 from "./Services/GoogleAnalystic";
import PageTracker from "./Components/GA4/PageTracker";

function App() {

  useEffect(() => {
    ga4.init();
  }, []);

  return <>
    <BrowserRouter>
      <Routes>
        <Route path="/error" element={<HeaderBase></HeaderBase>}>
          <Route path="429" element={<TooManyRequests></TooManyRequests>}></Route>
        </Route>

        <Route path="/hooman-verify" element={<BotDefeat></BotDefeat>}></Route>

        <Route path="/other" element={<AuthRequired><HeaderBase></HeaderBase></AuthRequired>}>
          <Route path="modular-inverse" element={<ModularInverse></ModularInverse>}></Route>
          <Route path="modulo" element={<Modulo></Modulo>}></Route>
        </Route>

        <Route path="/" element={<HeaderBase></HeaderBase>}>
          <Route path="" element={<Home></Home>}></Route>
          <Route path="/contact" element={<Contact></Contact>}></Route>
          <Route path="/terms-of-use" element={<TermsOfUse></TermsOfUse>}></Route>
        </Route>

        <Route path="/ciphers" element={<AuthRequired><HeaderBase></HeaderBase></AuthRequired>}>
          <Route path="" element={<Home></Home>}></Route>
          <Route path="caesar" element={<Caesar></Caesar>}></Route>
          <Route path="affine" element={<Affine></Affine>}></Route>
          <Route path="diffie-hellman" element={<DiffieHellman></DiffieHellman>}></Route>
          <Route path="playfair" element={<PlayFair></PlayFair>}></Route>
          <Route path="hill" element={<Hill></Hill>}></Route>
          <Route path="rsa" element={<RSA></RSA>}></Route>
          <Route path="vigenere" element={<Vigenere></Vigenere>}></Route>
        </Route>

        <Route element={<HeaderBase></HeaderBase>}>
          <Route path="*" element={<Home></Home>}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
    <Footer className="mt-4 mb-4"></Footer>
  </>;
}

export default App;
