import { Card, Col, Container, NavLink, Row } from "react-bootstrap";

export default function Home() {

    const ciphers = [
        "Affine", "Caesar", "Diffie-Hellman", "Hill", "PlayFair", "RSA",
        "Vigenere"
    ]

    return <>
        <Container>
            <h1 className="mt-4 fs-2">Thuật mã</h1>
            <Row className="mt-3">
                {ciphers.map(p => <Col xs={12} sm={12} md={4} lg={3}>
                    <NavLink href={`/ciphers/${p.toLocaleLowerCase()}`}>
                        <Card className="text-center py-3 fs-5 mb-2">
                            <h2 className="fs-4 mb-0">{p}</h2>
                        </Card>
                    </NavLink>
                </Col>)}

            </Row>

            <h1 className="mt-4 fs-2">Chức năng khác</h1>
            <Row className="mt-3">
                <Col xs={12} sm={12} md={4} lg={3}>
                    <NavLink href={`/other/modular-inverse`}>
                        <Card className="text-center py-3 fs-5 mb-2">
                            <h2 className="fs-4 mb-0">Nghịch đảo modulo</h2>
                        </Card>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={3}>
                    <NavLink href={`/other/modulo`}>
                        <Card className="text-center py-3 fs-5 mb-2">
                            <h2 className="fs-4 mb-0">Modulo số mũ lớn</h2>
                        </Card>
                    </NavLink>
                </Col>
            </Row>
        </Container>
    </>
}