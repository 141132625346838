import { Alert, Button, Card, Container, Modal, Spinner } from "react-bootstrap";
import Logo from "../Commons/Logo";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "../Commons/Footer";
import { useState } from "react";
import * as settings from "../../appsettings";
import httpClient from "../../Services/ApiService";
import endpoints from "../../apiendpoints";
import cookies from "react-cookies";
import { useNavigate } from "react-router-dom";
import * as ga4 from "../../Services/GoogleAnalystic";

export default function BotDefeat() {

    const [captchaLoading, setCaptchaLoading] = useState(true);
    const [alert, setAlert] = useState(null);
    const [processing, setProcessing] = useState(false);
    const nav = useNavigate();

    async function handleVerify(captchaToken) {
        if (!captchaToken)
            return;

        setProcessing(true);
        try {
            const res = await httpClient.post(endpoints["user.recaptcha.auth"], {
                token: captchaToken
            });

            const token = res?.data?.result?.accessToken;
            cookies.save("app_access_token", token, {path: "/"});
            ga4.trackAuthUser();
            nav("/");
        } catch (error) {
            setAlert("Xác nhận thất bại, vui lòng thử lại.")
        } finally {
            setProcessing(false);
        }
    };


    return <Container
        style={{ maxWidth: '100%', width: '400px' }}>
        <Card className="text-center py-3 px-2 mt-4">
            {alert && <Alert variant="warning" dismissible onClose={_ => setAlert(null)}>{alert}</Alert>}
            <Logo className="mb-4" size="md"></Logo>
            <h3>Xác nhận</h3>
            <p className="mb-0">Vui lòng xác nhận bạn không phải là người máy.</p>
            <small className="mb-4 text-muted">(Reload lại trang nếu captcha không hiển thị)</small>
            {captchaLoading && <div className="d-flex align-items-center justify-content-center">
                <Spinner></Spinner>
                <span className="ps-2 text-muted">Đang tải captcha...</span>
            </div>}
            <ReCAPTCHA
                asyncScriptOnLoad={_ => setCaptchaLoading(false)}
                hl="vi"
                sitekey={process.env["REACT_APP_RECAPTCHA_V2_SITE_ID"]}
                onChange={handleVerify}
            />
        </Card>

        {processing && <Modal
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show>
            <Modal.Body className="d-flex align-items-center">
                <Spinner></Spinner>
                <span className="ms-2">Đang xác nhận, vui lòng đợi</span>
            </Modal.Body>
        </Modal>}

    </Container>
}