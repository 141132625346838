import {
    Accordion,
    AlertHeading,
    Button,
    Card,
    Col,
    Container,
    Form,
    FormControl,
    Row,
    Spinner,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useState } from "react";
import * as settings from "../../../appsettings";
import httpClient from "../../../Services/ApiService";
import endpoints from "../../../apiendpoints";
import { HttpStatusCode } from "axios";
import { MathJax, MathJaxContext } from "better-react-mathjax";

export default function DiffieHellman() {

    const [isProcessing, setProcessing] = useState(false);
    const [res, setRes] = useState("");
    const resultRef = useRef();

    const schema = yup
        .object({
            g: yup
                .number()
                .typeError("Vui lòng nhập g")
                .required("Vui lòng nhập g")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            p: yup
                .number()
                .typeError("Vui lòng nhập p")
                .required("Vui lòng nhập p")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            a: yup
                .number()
                .typeError("Vui lòng nhập a")
                .required("Vui lòng nhập a")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            b: yup
                .number()
                .typeError("Vui lòng nhập b")
                .required("Vui lòng nhập b")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setError,
        watch,
        control
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    async function onSubmit(data) {
        setRes({});
        setProcessing(true);
        try {
            const param = Object.keys(data)
                .map((k) => `${k}=${data[k]}&`)
                .join("");
            let url = endpoints["cipher.diffie-hellman.keys"].concat("?", param);
            const res = await httpClient.get(url);
            setRes(res.data);
            resultRef.current?.focus();
        } catch (error) {
        } finally {
            setProcessing(false);
        }
    }

    function onChange(e, fieldOnChange) {
        if (res)
            setRes(null);
        fieldOnChange(e.target.value);
    }

    return <>
        <Container className="py-2">
            <h1>Diffie-Hellman</h1>

            <Card className="p-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm={6} className="mb-2">
                            <Form.Label className="ps-2">Nhập g:</Form.Label>
                            <Controller name="g" control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        isInvalid={Boolean(errors?.g)}
                                        size="lg"
                                        type="number"
                                        min={0}
                                        onChange={e => onChange(e, field.onChange)}
                                    ></Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.g?.message}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={6} className="mb-2">
                            <Form.Label className="ps-2">Nhập p:</Form.Label>
                            <Controller name="p" control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        isInvalid={Boolean(errors?.p)}
                                        size="lg"
                                        type="number"
                                        min={0}
                                        onChange={e => onChange(e, field.onChange)}
                                    ></Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.p?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={6} className="mb-2">
                            <Form.Label className="ps-2">Nhập a:</Form.Label>
                            <Controller name="a" control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        isInvalid={Boolean(errors?.a)}
                                        size="lg"
                                        type="number"
                                        min={0}
                                        onChange={e => onChange(e, field.onChange)}
                                    ></Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.a?.message}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={6}>
                            <Form.Label className="ps-2">Nhập b:</Form.Label>
                            <Controller name="b" control={control}
                                render={({ field }) => (
                                    <Form.Control
                                        {...field}
                                        isInvalid={Boolean(errors?.b)}
                                        size="lg"
                                        type="number"
                                        min={0}
                                        onChange={e => onChange(e, field.onChange)}
                                    ></Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.b?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <div className="text-end">
                        <Button
                            disabled={isProcessing}
                            type="submit"
                            variant="success"
                            className="mt-2"
                        >
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                />
                            )}
                            <span>Tìm khóa</span>
                        </Button>
                    </div>
                    <Row>
                        <Col xs={12} sm={12} md={4} className="mb-2">
                            <Form.Group>
                                <Form.Label className="ps-2">Khóa công khai A (K<sub>U,A</sub>):</Form.Label>
                                <Form.Control type="text" size="lg"
                                    ref={resultRef}
                                    value={res?.result?.aPublicKey ?? ""}></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="mb-2">
                            <Form.Group>
                                <Form.Label className="ps-2">Khóa công khai B (K<sub>U,B</sub>):</Form.Label>
                                <Form.Control type="text" size="lg" value={res?.result?.bPublicKey ?? ""}></Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12} sm={12} md={4} className="mb-2">
                            <Form.Group>
                                <Form.Label className="ps-2">Khóa bí mật (K<sub>R,A</sub> và K<sub>R,B</sub>):</Form.Label>
                                <Form.Control type="text" size="lg" value={res?.result?.privateKey ?? ""}></Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                </form>

                {res?.succeeded && <MathJaxContext>
                    <MathJax className="mt-3 ps-2">{`
                \\(
                K_{U,A} = g^a \\ mod \\ p =  ${watch('g')}^{${watch('a')}} \\ mod \\ ${watch('p')} = ${res?.result?.aPublicKey}
                \\)`}</MathJax>
                    <MathJax className="mt-2 ps-2">{`
                \\(
                K_{U,B} = g^b \\ mod \\ p =  ${watch('g')}^{${watch('b')}} \\ mod \\ ${watch('p')} = ${res?.result?.bPublicKey}
                \\)`}</MathJax>
                    <MathJax className="mt-2 ps-2">{`
                \\(
                K_{R,A} = {K_{U,B}}^a \\ mod \\ p =  ${res?.result?.bPublicKey}^{${watch('a')}} \\ mod \\ ${watch('p')} = ${res?.result?.privateKey}
                \\)`}</MathJax>
                    <MathJax className="mt-2 ps-2">{`
                \\(
                K_{R,B} = {K_{U,A}}^b \\ mod \\ p =  ${res?.result?.aPublicKey}^{${watch('b')}} \\ mod \\ ${watch('p')} = ${res?.result?.privateKey}
                \\)`}</MathJax>
                </MathJaxContext>}
            </Card>
        </Container>
    </>
}