import { HttpStatusCode } from "axios";
import * as settings from "../appsettings";

export default function exceptionHandler(error) {
    if (error.status == HttpStatusCode.Unauthorized) {
        window.location = settings.HUMAN_VERIFY_URL;
    }

    if (error.status == HttpStatusCode.TooManyRequests) {
        window.location = settings.ERR_429_URL;
    }
    throw {
        errCode: error.code,
        status: error.status,
        detail: error?.response?.data
    };
}