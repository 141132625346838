const endpoints = Object.freeze({
    'user.social_login': 'social',
    'cipher.caesar_encode': 'ciphers/caesar/ciphertext',
    'cipher.caesar_decode': 'ciphers/caesar/plaintext',
    'cipher.affine_encode': 'ciphers/affine/ciphertext',
    'cipher.affine_decode': 'ciphers/affine/plaintext',
    'cipher.diffie-hellman.keys': 'ciphers/diffie-hellman/keys',
    'cipher.playfair.encode': 'ciphers/playfair/ciphertext',
    'cipher.playfair.decode': 'ciphers/playfair/plaintext',
    'cipher.hill.encode': 'ciphers/hill/ciphertext',
    'cipher.hill.decode': 'ciphers/hill/plaintext',
    'cipher.rsa.findkeys': 'ciphers/rsa/keys',
    'cipher.rsa.encode': 'ciphers/rsa/ciphertext',
    'cipher.rsa.decode': 'ciphers/rsa/plaintext',
    'cipher.vigenere.encode': 'ciphers/vigenere/ciphertext',
    'cipher.vigenere.decode': 'ciphers/vigenere/plaintext',
    'user.recaptcha.auth': 'users/recaptcha/auth',
    'other.modular_inverse': 'other/modular-inverse',
    'other.modulo': 'other/modulo',
});

export default endpoints;