import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Logo from "../Commons/Logo";

export function Header() {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container>
        <Navbar.Brand href="/" className="fw-bold">
          <Logo size="sm" className="mt-0"></Logo>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">Trang chủ</Nav.Link>
            <Nav.Link href="/ciphers/affine">Affine</Nav.Link>
            <Nav.Link href="/ciphers/caesar">Caesar</Nav.Link>
            <Nav.Link href="/ciphers/diffie-hellman">Diffie-Hellman</Nav.Link>
            <Nav.Link href="/ciphers/hill">Hill</Nav.Link>
            <Nav.Link href="/ciphers/playfair">PlayFair</Nav.Link>
            <Nav.Link href="/ciphers/rsa">RSA</Nav.Link>
            <Nav.Link href="/ciphers/vigenere">Vigenere</Nav.Link>

            <NavDropdown title="Khác" id="basic-nav-dropdown">
              <NavDropdown.Item href="/other/modular-inverse">Nghịch đảo modulo</NavDropdown.Item>
              <NavDropdown.Item href="/other/modulo">Modulo số mũ lớn</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
