import { Container } from "react-bootstrap";
import Footer from "./Footer";

export default function TooManyRequests() {
    return <>
        <Container className="text-center">
            <img className="mt-4" style={{ width: '300px' }} src="/images/sink.png" />
            <h3>429 - Too many request</h3>
            <p>Bạn đã gửi quá nhiều yêu cầu trong một thời gian ngắn, vui lòng chờ và thử lại.</p>
        </Container>
    </>
}