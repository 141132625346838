import { Accordion, Alert, Button, Card, Col, Container, Form, Row, Spinner, Table } from "react-bootstrap";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import * as settings from "../../../appsettings";
import httpClient from "../../../Services/ApiService";
import endpoints from "../../../apiendpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { HttpStatusCode } from "axios";


export default function Modulo() {

    const [isProcessing, setProcessing] = useState(false);
    const [res, setRes] = useState(null);
    const resultRef = useRef();
    const [table, setTable] = useState([]);

    const schema = yup
        .object({
            base: yup
                .number()
                .typeError("Vui lòng nhập cơ số")
                .required("Vui lòng nhập cơ số")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            exp: yup
                .number()
                .typeError("Vui lòng nhập số mũ")
                .required("Vui lòng nhập số mũ")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            mod: yup
                .number()
                .typeError("Vui lòng nhập modulo")
                .required("Vui lòng nhập modulo")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch,
        setError
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    async function onSubmit(data) {
        setProcessing(true);
        try {
            const param = Object.keys(data)
                .map((k) => `${k}=${data[k]}&`)
                .join("");

            let url = endpoints["other.modulo"].concat("?", param);
            const res = await httpClient.get(url);

            setRes(res.data);
            const tmpTable = res?.data?.result?.table;
            tmpTable[0][2] = null;
            setTable(tmpTable);
            resultRef.current?.focus();
        } catch (error) {
        } finally {
            setProcessing(false);
        }
    }

    function onChange(e, fieldOnChange) {
        if (res)
            setRes(null);
        fieldOnChange(e.target.value);
    }

    return <>
        <Container className="py-2">
            <h1>Modulo</h1>
            <Card className="p-2">
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Row>
                        <Col sm={12} md={4}>
                            <Form.Label className="ps-2 mt-1">Nhập cơ số:</Form.Label>
                            <Controller name="base" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.base)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.base?.message}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={12} md={4}>
                            <Form.Label className="ps-2 mt-1">Nhập số mũ:</Form.Label>
                            <Controller name="exp" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.exp)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.exp?.message}
                            </Form.Control.Feedback>
                        </Col>
                        <Col sm={12} md={4}>
                            <Form.Label className="ps-2 mt-1">Nhập modulo:</Form.Label>
                            <Controller name="mod" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.mod)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.mod?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>

                    <div className="text-end">
                        <Button
                            disabled={isProcessing}
                            type="submit"
                            variant="success"
                            className="mt-2"
                        >
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                />
                            )}
                            <span>Tính kết quả</span>
                        </Button>
                    </div>

                    <Form.Label className="ps-2 mt-1">Kết quả:</Form.Label>
                    <Form.Control size="lg" ref={resultRef} value={res?.result?.result ?? ""}></Form.Control>
                </form>
            </Card>
        </Container >
    </>
}