import { Accordion, Alert, Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { MathJax, MathJaxContext } from "better-react-mathjax";
import { useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import * as settings from "../../../appsettings";
import httpClient from "../../../Services/ApiService";
import endpoints from "../../../apiendpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { HttpStatusCode } from "axios";

export default function Encode() {

    const [isProcessing, setProcessing] = useState(false);
    const [useKx, setUseKx] = useState(true);
    const [res, setRes] = useState(null);
    const alphabet = settings.ALPHABET;
    const [kMatrix, setKMatrix] = useState("");
    const [cipherBlocks, setCipherBlocks] = useState(null);
    const [alert, setAlert] = useState(null);
    const resultRef = useRef();

    const schema = yup
        .object({
            a: yup
                .number()
                .typeError("Vui lòng nhập a")
                .required("Vui lòng nhập a")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            b: yup
                .number()
                .typeError("Vui lòng nhập b")
                .required("Vui lòng nhập b")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            c: yup
                .number()
                .typeError("Vui lòng nhập c")
                .required("Vui lòng nhập c")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            d: yup
                .number()
                .typeError("Vui lòng nhập d")
                .required("Vui lòng nhập d")
                .min(0, 'Giá trị tối thiểu bằng 0')
                .max(settings.MAX_INT_KEY, `Giá trị tối đa ${settings.MAX_INT_KEY}`),
            plainText: yup
                .string()
                .required("Vui lòng nhập bản rõ")
                .max(settings.MAX_PLAIN_TEXT_LENGTH, `Tối đa ${settings.MAX_PLAIN_TEXT_LENGTH} ký tự`)
                .test({
                    name: "alphabet_valid",
                    test: function (value) {
                        return ![...value.toLowerCase()].some(
                            (char) => !alphabet.includes(char)
                        );
                    },
                    message:
                        "Chỉ chấp nhận các ký tự từ a-z (không phân biệt hoa thường)",
                }),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        watch
    } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    async function onSubmit(data) {
        setProcessing(true);
        setKMatrix(`
            \\begin{bmatrix}
            ${data.a} & ${data.b} \\\\
            ${data.c} & ${data.d}
            \\end{bmatrix}`);

        try {
            data["useKxFunc"] = useKx;
            const param = Object.keys(data)
                .map((k) => `${k}=${data[k]}&`)
                .join("");
            let url = endpoints["cipher.hill.encode"].concat("?", param);
            const res = await httpClient.get(url);
            setRes(res.data);
            setCipherBlocks(res?.data?.result?.cipherText?.match(/.{1,2}/g));
            resultRef.current?.focus();
        } catch (error) {
            if (error.status == HttpStatusCode.BadRequest && error.detail.error == "no_modular_inverse") {
                const detK = (parseInt(watch('a')) * parseInt(watch('d')) - parseInt(watch('c')) * parseInt(watch('b')));
                setAlert(`Không tồn tại nghịch đảo modulo ${alphabet.length} của det(k) = ${detK}`)
            }
        } finally {
            setProcessing(false);
        }
    }

    function onChange(e, fieldOnChange) {
        if (res)
            setRes(null);
        fieldOnChange(e.target.value);
    }

    function onFuncChange(field, e) {
        setUseKx(field == "kx");
        if (res)
            setRes(null);
    }

    return <>
        <Accordion.Item eventKey="0" defaultChecked>
            <Accordion.Header>
                <h5>Mã hóa</h5>
            </Accordion.Header>
            <Accordion.Body className="p-2">
                {alert && <Alert variant="danger" onClose={_ => setAlert(null)} dismissible>{alert}</Alert>}
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Label className="ps-2 mt-1">Khóa k:</Form.Label>
                    <Row>
                        <Col xs={6} sm={6} className="mb-2">
                            <Controller name="a" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" placeholder="a" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.a)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.a?.message}
                            </Form.Control.Feedback>
                        </Col>
                        <Col xs={6} sm={6}>
                            <Controller name="b" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" placeholder="b" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.b)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.b?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6} sm={6}>
                            <Controller name="c" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" placeholder="c" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.c)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.c?.message}
                            </Form.Control.Feedback>
                        </Col>
                        <Col xs={6} sm={6}>
                            <Controller name="d" control={control}
                                render={({ field }) => (
                                    <Form.Control size="lg" placeholder="d" type="number"
                                        {...field}
                                        isInvalid={Boolean(errors?.d)}
                                        onChange={e => onChange(e, field.onChange)}>
                                    </Form.Control>
                                )}></Controller>
                            <Form.Control.Feedback type="invalid" className="ps-2">
                                {errors.d?.message}
                            </Form.Control.Feedback>
                        </Col>
                    </Row>
                    <Form.Group>
                        <Form.Label className="ps-2 mt-1">Bản rõ:</Form.Label>
                        <Controller name="plainText" control={control}
                            render={({ field }) => (
                                <Form.Control size="lg" type="text"
                                    {...field}
                                    isInvalid={Boolean(errors?.plainText)}
                                    onChange={e => onChange(e, field.onChange)}>
                                </Form.Control>
                            )}></Controller>
                        <Form.Control.Feedback type="invalid" className="ps-2">
                            {errors.plainText?.message}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="d-flex align-items-center">
                        <Form.Label className="ps-2 mt-1">Hàm mã:</Form.Label>
                        <Form.Check id="kx" name="func" type="radio" label="y = kx" className="ms-4"
                            defaultChecked={useKx} onChange={e => onFuncChange("kx", e)}></Form.Check>
                        <Form.Check name="func" type="radio" label="y = xk" className="ms-3"
                            defaultChecked={!useKx} onChange={e => onFuncChange("xk", e)}></Form.Check>
                    </Form.Group>

                    <div className="text-end">
                        <Button
                            disabled={isProcessing}
                            type="submit"
                            variant="success"
                            className="mt-2"
                        >
                            {isProcessing && (
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="me-1"
                                />
                            )}
                            <span>Mã hóa</span>
                        </Button>
                    </div>

                    <Form.Group>
                        <Form.Label className="ps-2 mt-1">Bản mã:</Form.Label>
                        <Form.Control type="text" size="lg"
                            ref={resultRef}
                            value={res?.result?.cipherText?.toUpperCase() ?? ""}></Form.Control>
                    </Form.Group>
                </form>
                <div className="mt-4" style={{overflowX: 'auto'}}>
                    <MathJaxContext>
                        {res?.result?.newPlainText?.match(/.{1,2}/g)
                            .map((p, i) => {
                                const x = `
                            \\begin{bmatrix}
                            ${alphabet.indexOf(p[0].toLowerCase())}\\\\
                            ${alphabet.indexOf(p[1].toLowerCase())}
                            \\end{bmatrix}`;

                                const c = `
                            \\begin{bmatrix}
                            ${alphabet.indexOf(cipherBlocks[i][0])}\\\\
                            ${alphabet.indexOf(cipherBlocks[i][1])}
                            \\end{bmatrix}`;

                                let expression = `
                            \\( C_{${p}} = ${kMatrix} * ${x} mod
                            \\ ${alphabet.length} =  ${c} = ${cipherBlocks[i].toUpperCase()}
                            \\)`;

                                if (!useKx)
                                    expression = `
                            \\( C_{${p}} = ${x} * ${kMatrix} mod
                            \\ ${alphabet.length} =  ${c} = ${cipherBlocks[i].toUpperCase()}
                            \\)`;
                                return <p><MathJax className="mt-2 ps-2">{expression}</MathJax></p>

                            })}
                    </MathJaxContext>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    </>
}